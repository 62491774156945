import * as React from 'react'
import { graphql } from 'gatsby'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import * as styles from "./{mdx.slug}.module.css"
import Layout from '../../components/layout'
import Header from '../../components/header'

const BlogPost = ({ data }) => {
  return (
    <Layout>
      <title>Watch+ | {data.mdx.frontmatter.title}</title>
      <div className={styles.blogPost}>
        <Header title={data.mdx.frontmatter.title} description={data.mdx.frontmatter.date}/>
        <MDXRenderer>
          {data.mdx.body}
        </MDXRenderer>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    mdx(id: {eq: $id}) {
      frontmatter {
        title
        date(formatString: "MMMM D, YYYY")
      }
      body
    }
  }
`

export default BlogPost